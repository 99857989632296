import React, {
  createContext,
  FunctionComponent,
  useState,
  useEffect,
  useContext,
} from "react";
import moment from "moment";
import {
  getSlug,
  RouteConfig,
  RoutesConfig,
  useCurrentRoute,
  replaceSlug,
  goTo,
  hasPartnerPortalManagerRole,
  getCurrentUserAccount,
  storeSlug,
  isRemoteLogin,
  getParams,
  deleteQueryParam,
  isSeenspireConnectHost,
} from "../routes";
import {
  ProgressBar,
  theme as _theme,
  ITheme,
  hexToHSL,
} from "../../seenspire-library";
import backend from "../backend";
import { rtdb } from "../backend/firebase";
import {
  UserMe,
  Account,
  PartnerSettings,
  DisplaySettings2,
  Account8,
  PartnerSettings5,
  IClientApi,
  Stream,
  StreamActiveChannel,
} from "seenspire-api-client";
import useRouter from "use-react-router";
import { isEmpty, find, get, intersection, flowRight } from "lodash";
import { AuthRoutNames } from "../routes/AuthRoutes";
import {
  AccountUserRoleMe,
  Channel,
  UserRoles,
  AppDisplayModeType,
  ISeenspireConnectMessage,
  ISeenspireConnectConfig,
  SeenspireConnectMessageActionEnums,
  IPostFeedData,
} from "../interfaces";
import {
  clearSession,
  getToken,
  generateToken,
  storeToken,
  clearTokenParams,
} from "../backend/session";
import { ITrackData, track } from "../backend/tracker";
import { PermissionKeyType, Permissions } from "../permissions";
import { FeedType, isClient } from "../util";
import { IGNORED_SLUGS } from "../backend/consts";

ProgressBar.configure({
  showSpinner: false,
});
export const initialTheme = {
  ..._theme,
  layout: {
    ..._theme.layout,
    sidePadding: 40,
    gap: 20,
    gridColumnTemplate: "repeat(auto-fit, minmax(350px, 1fr))",
    customColors: {
      upgradeColor: {
        base: "#FEB454",
        dark: "#F9A941",
      },
    },
  },
};

const generateTheme = (
  partnerSettings: PartnerSettings,
  theme: ITheme
): ITheme => {
  const _cta = hexToHSL(partnerSettings.accent_color);
  return {
    ...theme,
    colors: {
      ...theme.colors,
      primary: partnerSettings.navigation_background_color
        ? {
            lightest: hexToHSL(partnerSettings.navigation_background_color, 30),
            lighter: hexToHSL(partnerSettings.navigation_background_color, 20),
            light: hexToHSL(partnerSettings.navigation_background_color, 10),
            base: hexToHSL(partnerSettings.navigation_background_color),
            dark: hexToHSL(partnerSettings.navigation_background_color, -20),
            darker: hexToHSL(partnerSettings.navigation_background_color, -30),
            darkest: hexToHSL(partnerSettings.navigation_background_color, -40),
          }
        : theme.colors.primary,
      cta: partnerSettings.accent_color
        ? {
            lightest: _cta,
            lighter: _cta,
            light: _cta,
            base: _cta,
            dark: _cta,
            darker: _cta,
            darkest: _cta,
          }
        : theme.colors.cta,
    },
  };
};

export interface IAppContext {
  redirect: IRedirect;
  authenticated: boolean;
  activated: boolean;
  theme: ITheme;
  currentRoute: RouteConfig;
  exactRoute: RouteConfig;
  client: IClientApi;
  user: UserMe;
  partnerSettings: PartnerSettings;
  account: AccountUserRoleMe;
  slug: string;
  loading: boolean;
  error: IError;
  stickyNotification: IStickyNotificationConfig;
  title: string;
  goTo: (routeName: string, reload?: boolean, replace?: boolean) => void;
  logout: () => void;
  setTheme: (theme: ITheme) => void;
  setError: (error: IError) => void;
  setStickyNotification: (config: IStickyNotificationConfig) => void;
  setTitle: (title: string) => void;
  setLoading: (loading: boolean) => void;
  setClient: React.Dispatch<React.SetStateAction<IClientApi>>;
  setMyChannels: (channels: Channel[]) => void;
  myChannels: Channel[] | null;
  setMyStreams: (channels: Stream[]) => void;
  myStreams: Stream[] | null;
  loadedStream: Stream | null;
  setLoadedStream: (stream: Stream) => void;
  openStreams: boolean;
  setOpenStreams: (b: boolean) => void;
  switchAccount: (account: Account, routeName?: string) => Promise<void>;
  _switchAccount: (
    account: Account,
    routeName?: string,
    withLoadingIndicator?: boolean,
    forceReload?: boolean
  ) => Promise<void>;
  switcherRequired: () => boolean;
  confirmEmailModalIsOpen: boolean;
  setConfirmEmailModalIsOpen: (open: boolean) => void;
  acceptTermsModalIsOpen: boolean;
  setAcceptTermsModalIsOpen: (open: boolean) => void;
  setReAuthenticate: (status: boolean) => void;
  fetchUser: () => Promise<UserMe>;
  reAuthenticate?: boolean;
  CtaConfig?: {
    hidden?: boolean;
    action: (...arg: any[]) => any;
    label: string;
    submitted?: boolean;
    submitting?: boolean;
    error?: boolean;
  };
  resetCtaConfig: () => void;
  setCtaConfig: any;
  deleteChannel: Channel | undefined;
  setDeleteChannel: React.Dispatch<React.SetStateAction<Channel | undefined>>;
  deleteStreamActiveChannel: StreamActiveChannel | undefined;
  setDeleteStreamActiveChannel: React.Dispatch<
    React.SetStateAction<StreamActiveChannel | undefined>
  >;
  notificationConfig: any;
  setNotificationConfig: any;
  hasPermission: (permissionKey: PermissionKeyType) => boolean;
  hasRole: (role: UserRoles) => boolean;
  hasFeature: (feature: FeedType) => boolean;
  hasTrialStarted: () => boolean;
  hasTrialEnded: () => boolean;
  requestFeatureModalIsOpen: FeedType | undefined;
  setRequestFeatureModalIsOpen: (feature: FeedType | undefined) => void;
  requestTrialModalIsOpen: FeedType | undefined;
  setRequestTrialModalIsOpen: (feature: FeedType | undefined) => void;
  switchAccountModalIsOpen: ISwitchAccountParams | undefined;
  setSwitchAccountModalIsOpen: (data: ISwitchAccountParams | undefined) => void;
  showPoweredBy: boolean;
  appDisplayMode: AppDisplayModeType;
  seenspireConnectConfig: ISeenspireConnectConfig;
  twitterEnabled: boolean;
  isParentMaster: boolean;
}

interface ISwitchAccountParams {
  account: Account;
  routeName: string;
}

export interface IError {
  error: boolean;
  title?: string;
  subTitle?: string;
  message?: string;
  body?: string;
  errorResponse?: any;
  defaults?: boolean;
  onCancel?: () => Promise<any>;
  onConfirm?: () => Promise<any>;
}
export interface IStickyNotificationConfig {
  active: boolean;
  ctaLabel?: string;
  title?: string;
  subTitle?: string;
  message?: string;
  body?: string;
  onCancel?: () => Promise<any>;
  onConfirm?: () => Promise<any>;
}

const EmptyPartnerSettings: PartnerSettings5 = {
  name: "",
  navigation_background_color: "",
  navigation_text_color: "",
  accent_color: "",
  navigation_logo: "",
  login_logo: "",
  contact_name: "",
  phone_number: "",
  twitter_url: "",
  instagram_url: "",
  facebook_url: "",
  linked_in: "",
  id: 0,
  allow_logo: false,
  allow_theming: false,
  account_logo: "",
};

const EmptyOrganization = {
  name: "",
  vat: "",
  street: "",
  number: "",
  box: "",
  zip: "",
  city: "",
  email: "",
  phone: "",
  country: "",
  slug: "",
  region: "",
  id: 0,
};

const EmptyDisplaySettings: DisplaySettings2 = {
  id: 0,
  color: "",
  time_per_slide: 1,
  frequency: 1,
  random: false,
  intro: false,
  dot_overlay: false,
  content_option: "",
  font: "",
  performance: "",
  theme: "",
  logo_type: "",
  logo_text: "",
  logo_image: "",
  audio: false,
  image_caption: false,
  video_visibility: false,
  image_visibility: false,
  text_visibility: false,
  video_caption: false,
  ratio: "",
  size: "",
  logo_scale: "",
  image_mode: "",
  secondary_color: "",
  profile_position: "",
  social_background: false,
  created_by: { id: 0, email: "", first_name: "", last_name: "" },
  updated_by: { id: 0, email: "", first_name: "", last_name: "" },
  created_at: new Date(),
  updated_at: new Date(),
  show_profile: true,
  logo_image_dark: "",
  font_path: "",
  single_slide: false,
  show_cities: false,
  advanced_weather: false,
};

const EmptyRole: any = {
  name: "",
  type: "",
};

const EmptyAccount: AccountUserRoleMe = {
  id: 0,
  account: {
    id: 0,
    organization_info: EmptyOrganization,
    display_settings: EmptyDisplaySettings,
    partner_settings: EmptyPartnerSettings,
    type: "",
    status: Account8.StatusEnum.ACTIVE,
    activity: Account8.ActivityEnum.LOW,
    is_lead: false,
    is_system: false,
    disabled: false,
    referral: false,
    subscriptions: [],
    livehours_allowed: false,
    almost_expired: false,
    airtime: {},
    deleted: false,
    channel_types: [],
    extra_trial_started_at: new Date(),
    is_extra_trial_finished: false,
    extra_trial_choice: "",
    deleted_at: new Date(),
    live_hours: {
      seconds: 0,
      start_at: new Date(),
      end_at: new Date(),
    },
    terms_accepted: true,
    available_fonts: {},
    work_os_organization: "",
    work_os_connection: "",
    workosredirect: "",
    twitter_enabled: false,
    realtime_id: null,
  },
  roles: [EmptyRole],
};

const EmptyUser: UserMe = {
  avatar: "",
  email: "",
  first_name: "",
  last_name: "",
  last_used_account: 0,
  account_users: [EmptyAccount],
  confirmed: false,
};
const SEENSPIRE_CONNECT_VERSION = 2;
function postFeedData({ feedUrl, slug, code }: IPostFeedData) {
  postMessage({
    action: SeenspireConnectMessageActionEnums.SSC_SOURCE_OPERATION_DONE,
    payload: { presentationUrl: feedUrl, slug, code },
  });
}

function postMessage({ action, payload: _payload }: ISeenspireConnectMessage) {
  const token = generateToken(getToken());
  const payload = {
    ..._payload,
    token,
    version: SEENSPIRE_CONNECT_VERSION,
    timestamp: new Date().toISOString(),
  };
  window.parent.postMessage({ message: { action, payload } }, "*");
}

function notifyUpdated() {
  postMessage({
    action:
      SeenspireConnectMessageActionEnums.SSC_SOURCE_OPERATION_NOTIFY_UPDATED,
    payload: {},
  });
}

const EmptySeenspireConnectConfig: ISeenspireConnectConfig = {
  active: false,
  code: "",
  token: null,
  customData: {},
  source: null,
  origin: null,
  postFeedData,
  postMessage,
  notifyUpdated,
};

const initialAppContext: IAppContext = {
  redirect: { pathname: "/", route: RoutesConfig[0], query: {} },
  authenticated: false,
  activated: false,
  confirmEmailModalIsOpen: false,
  acceptTermsModalIsOpen: false,
  partnerSettings: EmptyPartnerSettings,
  theme: initialTheme,
  currentRoute: RoutesConfig[0], // Dashboard
  exactRoute: RoutesConfig[0], // Dashboard
  client: backend.loadClient(),
  user: EmptyUser,
  account: EmptyAccount,
  slug: "",
  loading: false,
  error: { error: false },
  stickyNotification: { active: false },
  title: "",
  myChannels: null,
  myStreams: null,
  loadedStream: null,
  openStreams:
    localStorage.getItem("streamsOpen") &&
    localStorage.getItem("streamsOpen") === "true"
      ? true
      : false,
  deleteChannel: undefined,
  deleteStreamActiveChannel: undefined,
  goTo: (routeName: string, reload?: boolean) => {},
  logout: () => {},
  setTheme: () => {},
  setClient: () => {},
  setError: () => {},
  setStickyNotification: () => {},
  setTitle: () => {},
  setMyChannels: () => {},
  setMyStreams: () => {},
  setOpenStreams: () => {},
  setLoadedStream: () => {},
  setConfirmEmailModalIsOpen: () => {},
  setAcceptTermsModalIsOpen: () => {},
  setLoading: (loading: boolean) => {},
  setReAuthenticate: (loading: boolean) => {},
  fetchUser: () => Promise.resolve(EmptyUser),
  switcherRequired: () => false,
  switchAccount: (account: Account, routeName?: string) => Promise.resolve(),
  _switchAccount: (
    account: Account,
    routeName?: string,
    forceReload?: boolean
  ) => Promise.resolve(),
  setDeleteChannel: (status) => {},
  setDeleteStreamActiveChannel: (status) => {},
  setCtaConfig: () => undefined,
  CtaConfig: {
    action: () => {},
    label: "",
  },
  resetCtaConfig: () => {},
  notificationConfig: undefined,
  setNotificationConfig: () => {},
  hasPermission: (key) => true,
  hasRole: (role) => true,
  hasFeature: (feature) => true,
  hasTrialStarted: () => true,
  hasTrialEnded: () => true,
  requestFeatureModalIsOpen: undefined,
  setRequestFeatureModalIsOpen: (feature) => {},
  requestTrialModalIsOpen: undefined,
  setRequestTrialModalIsOpen: (feature) => {},
  switchAccountModalIsOpen: undefined,
  setSwitchAccountModalIsOpen: (data) => {},
  showPoweredBy: true,
  appDisplayMode: "STANDARD",
  seenspireConnectConfig: EmptySeenspireConnectConfig,
  twitterEnabled: false,
  isParentMaster: false,
};

interface IRedirect {
  query: any;
  pathname: string;
  route: RouteConfig;
}

const AppContext = createContext<IAppContext>(initialAppContext);

const { Provider, Consumer } = AppContext;

const AppProvider: FunctionComponent = (props) => {
  const queryParams = getParams();

  const { location, history } = useRouter();
  const [slug, setSlug] = useState<string>(getSlug());
  const [token, setToken] = useState(getToken());
  const [currentRoute, setCurrentRoute] = useState<RouteConfig>(
    useCurrentRoute(location, RoutesConfig).currentRoute
  );
  const [exactRoute, setExactRoute] = useState<RouteConfig>(
    useCurrentRoute(location as any, RoutesConfig).exactRoute
  );
  const [showPoweredBy, setShowPoweredBy] = useState<boolean>(false);
  const [redirect, setRedirect] = useState<IRedirect>({
    pathname: location.pathname,
    route: currentRoute,
    query: new URLSearchParams(location.search),
  });

  const [requestFeatureModalIsOpen, setRequestFeatureModalIsOpen] =
    useState<FeedType>();
  const [requestTrialModalIsOpen, setRequestTrialModalIsOpen] =
    useState<FeedType>();
  const [switchAccountModalIsOpen, setSwitchAccountModalIsOpen] =
    useState<ISwitchAccountParams>();
  const [confirmEmailModalIsOpen, setConfirmEmailModalIsOpen] =
    useState<boolean>(false);
  const [acceptTermsModalIsOpen, setAcceptTermsModalIsOpen] =
    useState<boolean>(false);
  const [deleteChannel, setDeleteChannel] = useState<Channel | undefined>(
    undefined
  );

  const [deleteStreamActiveChannel, setDeleteStreamActiveChannel] = useState<
    StreamActiveChannel | undefined
  >(undefined);

  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [reAuthenticate, setReAuthenticate] = useState<boolean>(false);
  const [activated, setActivated] = useState<boolean>(false);

  const [theme, setTheme] = useState<ITheme>(initialTheme);
  const [partnerSettings, setPartnerSettings] =
    useState<PartnerSettings>(EmptyPartnerSettings);
  const [myChannels, setMyChannels] = useState<Channel[] | null>(null);
  const [myStreams, setMyStreams] = useState<Stream[] | null>(null);
  const [openStreams, setOpenStreams] = useState<boolean>(
    localStorage.getItem("streamsOpen") &&
      localStorage.getItem("streamsOpen") === "true"
      ? true
      : false
  );
  const [loadedStream, setLoadedStream] = useState<Stream | null>(null);
  const [twitterEnabled, setTwitterEnabled] = useState<boolean>(false);
  const [user, setUser] = useState<UserMe>(EmptyUser);
  const [account, setAccount] = useState<AccountUserRoleMe>(EmptyAccount);
  const [title, setTitle] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [CtaConfig, setCtaConfig] = useState();
  const [error, setError] = useState<IError>({ error: false });
  const [stickyNotification, setStickyNotification] =
    useState<IStickyNotificationConfig>({ active: false });
  const [client, setClient] = useState<IClientApi>(backend.loadClient());
  const [notificationConfig, setNotificationConfig] = useState<any>();
  const [appDisplayMode, setAppDisplayMode] = useState<AppDisplayModeType>(
    isSeenspireConnectHost() ? "SEENSPIRE_CONNECT" : "STANDARD"
  );
  const [seenspireConnectConfig, setSeenspireConnectConfig] =
    useState<ISeenspireConnectConfig>(EmptySeenspireConnectConfig);

  useEffect(() => {
    const slugHandler: (this: Window, ev: StorageEvent) => any = ({
      newValue,
      oldValue,
      key,
    }) => {
      const isIgnoredSlug =
        !IGNORED_SLUGS.includes(String(oldValue)) ||
        !IGNORED_SLUGS.includes(String(newValue));
      if (key === "slug" && isIgnoredSlug) {
        window.history.pushState(undefined, "", `/${newValue}/dashboard`);
        window.location.reload();
      }
    };
    window.addEventListener("storage", slugHandler);
    return () => {
      return window.removeEventListener("storage", slugHandler);
    };
  }, []);

  // Session tasks
  useEffect(() => {
    if (queryParams.token) {
      storeToken({ data: token });
      clearTokenParams();
    }
  }, [queryParams]);

  // PartnerSettings
  useEffect(() => {
    loadAndSetPartnerSettings();
  }, [slug]);

  useEffect(() => {
    setTwitterEnabled(!!account.account.twitter_enabled);
  }, [account]);

  useEffect(() => {
    loading ? ProgressBar.start() : ProgressBar.done();
  }, [loading]);

  useEffect(() => {
    const _dontShowPoweredBy = ["feed_settings", "account_change_subscription"];
    const isMaster =
      get(account, "account.organization_info.slug") === "seenspire";
    const isParentMaster =
      get(account, "account.parent.organization_info.slug") === "seenspire";
    const _showPoweredBy =
      exactRoute &&
      !_dontShowPoweredBy.includes(exactRoute.name) &&
      !isMaster &&
      !isParentMaster
        ? true
        : false;
    setShowPoweredBy(_showPoweredBy);
  }, [exactRoute, account]);

  // Current route
  useEffect(() => {
    const { currentRoute: _currentRoute, exactRoute: _exactRoute } =
      useCurrentRoute(location, RoutesConfig);
    _currentRoute && setCurrentRoute(_currentRoute);
    _exactRoute && setExactRoute(_exactRoute);
    _currentRoute && setCtaConfig(undefined);

    setSlug(getSlug());
    storeSlug(getSlug());

    setToken(getToken());
    // console.log(generateToken(token))
  }, [location.key]);

  const processData = (data?: Record<string, any>): ITrackData | undefined => {
    const userId = get(data, "user.email");
    const accountId = get(data, "account.account.id");
    const organization_name = get(
      data,
      "account.account.organization_info.name"
    );
    const airtime = get(data, "account.account.airtime.total");
    const account_status = get(data, "account.account.status");
    const revenue = get(data, "account.account.revenue");
    const licences = get(data, "account.account.subscriptions[0].licences");
    const plan_name = get(data, "account.account.subscriptions[0].plan.name");
    const subscription_created_at = get(
      data,
      "account.account.subscriptions[0].created_at"
    );
    const subscription_expires_on = get(
      data,
      "account.account.subscriptions[0].expires_on"
    );
    const subscription_last_billed_at = get(
      data,
      "account.account.subscriptions[0].last_billed_at"
    );
    const _account_users = get(data, "account.account.account_users");
    const account_users = Array.isArray(_account_users)
      ? _account_users
          .filter((_au: any) =>
            _au.roles.find((_role: any) => {
              return !["ROLE_PARTNER_PORTAL_MANAGER", ""].includes(_role.slug);
            })
          )
          .map(
            (_au: any) =>
              `${_au.user.first_name} ${_au.user.last_name} (${
                _au.roles[0].slug as string
              })`
          )
      : [];

    if (!userId || !accountId) {
      return undefined;
    }

    return {
      userId,
      accountId,
      airtime,
      account_status,
      revenue,
      licences,
      plan_name,
      account_users,
      organization_name,
      subscription_created_at: moment(subscription_created_at).toISOString(),
      subscription_expires_on: moment(subscription_expires_on).toISOString(),
      subscription_last_billed_at: moment(
        subscription_last_billed_at
      ).toISOString(),
    };
  };

  // Authentication
  useEffect(() => {
    if (loading) return;
    setLoading(true);
    authenticate()
      .then(async (data) => {
        const trackData = processData(data);
        if (trackData) {
          track(trackData);
        }
      })
      .finally(() => setLoading(false));
  }, [client]);

  useEffect(() => {
    if (get(history.location.state, "reAuthenticate") && !loading) {
      setReAuthenticate(true);
      history.replace(history.location.pathname, {});
    }

    if (currentRoute.open) {
      setStickyNotification({ active: false });
    }
  }, [currentRoute]);

  useEffect(() => {
    if (!reAuthenticate) return;
    setLoading(true);
    console.log("authenticate 3");
    authenticate()
      .then(async (data) => {
        const trackData = processData(data);
        if (trackData) {
          track(trackData);
        }
      })
      .finally(() => {
        setReAuthenticate(false);
        setLoading(false);
      });
  }, [reAuthenticate]);

  const initializeNotifications = async () => {
    if (!rtdb) return;
    await rtdb.ref("notifications").on("value", (a) => {
      const data = a.val() || {};
      const { active, title } = data;
      if (active) {
        setStickyNotification({ active: true, title });
      } else {
        // setStickyNotification({ active: false });
      }
    });
  };

  const loadAndSetPartnerSettings = async () => {
    if (window.location.href.includes("workos/callback")) {
      const redirectUrl = `${process.env.REACT_APP_API}/workos/callback${window.location.search}`;
      const result = await fetch(redirectUrl);
      const resultJson = await result.json();
      storeToken({ data: resultJson });
      const params = getParams();
      const partner = JSON.parse(params["state"]);
      window.location.href = `/${partner.slug}`;
    } else {
      try {
        const _partnerSettings =
          await client.OpenPartnerSettings.getPartnerSettingsById(slug);
        setPartnerSettings(_partnerSettings);
        setTheme(generateTheme(_partnerSettings, theme));
      } catch (error) {
        // @TODO Improve UX by informing the user
        // that the accounted they requested is not found
        if (get(error, "response.data") === "Not Found") {
          history.push("/");
          window.location.reload();
        }
      }
    }
  };

  const resetCtaConfig = () => {
    setCtaConfig((prev: any) => ({
      ...prev,
      error: false,
      submitted: false,
      submitting: false,
    }));
  };

  const authenticate = async () => {
    console.log("[authenticate] current route: ", currentRoute.name);

    if (currentRoute.name === "redirection") {
      console.log(`[authenticate] Redirection route, skipping authentication.`);
      return;
    }

    if (currentRoute.open) {
      console.log(
        `[authenticate] Open route (${currentRoute.name}) skipping authentication, clearing session`
      );
      currentRoute.open ? clearSession() : logout();
      return;
    }

    if (!token) {
      console.log(
        `[authenticate] current route ${currentRoute.name} No token found, logging out!`
      );
      return logout();
    }

    // @TODO Catch error
    const _user = await fetchUser();

    let _account = !_user.last_used_account
      ? _user.account_users[0]
      : find(
          _user.account_users,
          ({ account }) => account.id === _user.last_used_account
        );

    if (!_account) {
      try {
        const targetAccount = (await client.Accounts.getAccountByUuid(
          String(_user.last_used_account)
        )) as any;
        _account = { account: targetAccount } as any;
      } catch (error) {
        setError({
          error: true,
          title: "Access denied",
          message:
            "It seems you do not have access to this account, you will be redirected to your other account",
          onConfirm: () => {
            return _switchAccount(_user.account_users[0].account as any);
          },
          onCancel: () => {
            return _switchAccount(_user.account_users[0].account as any);
          },
        });
        return;
      }
    }

    if (get(_account, "account.organization_info.slug") !== slug) {
      const _pathname = replaceSlug(
        location.pathname,
        get(_account, "account.organization_info.slug")
      );
      goTo(_pathname, true, true);
      return {};
    }

    setUser(_user);
    setAuthenticated(true);
    setActivated(_user.confirmed);

    if (_account) {
      setAccount(_account as AccountUserRoleMe);
    }

    if (isRemoteLogin(_user, slug)) {
      setStickyNotification({
        active: true,
        title: "Attention. You are in the client’s account",
        subTitle: "Any changes you make will affect the customer’s account.",
      });
    } else {
      setStickyNotification({
        active: false,
      });

      if (_account && !get(_account, "account.terms_accepted")) {
        setAcceptTermsModalIsOpen(true);
      } else {
        setAcceptTermsModalIsOpen(false);
      }
    }

    await initializeNotifications();
    return { account: _account, user: _user };
  };

  useEffect(() => {
    setConfirmEmailModalIsOpen(
      authenticated &&
        !activated &&
        !AuthRoutNames.includes(currentRoute.name as any)
    );
  }, [authenticated, activated]);

  const switchAccount = async (
    account: Account,
    routeName: string = "dashboard"
  ) => {
    setSwitchAccountModalIsOpen({ account, routeName });
  };

  const _switchAccount = async (
    account: Account,
    _routeName?: string,
    withLoadingIndicator?: boolean,
    forceReload?: boolean
  ) => {
    const routeName = _routeName || "partner/home";
    if (withLoadingIndicator) {
      setLoading(true);
    }
    try {
      await client.Users.postSwitchaccount({ account_id: account.id });
      if (withLoadingIndicator) {
        setLoading(false);
      }
      const _pathname = `/${account.organization_info.slug}/${routeName}`;
      history.push(_pathname, { reAuthenticate: true });
      if (forceReload) {
        window.location.reload();
      }
    } catch (error) {
      setError({
        error: true,
        title: "Access denied",
        message: "It seems you do not have access to this account",
      });
      if (withLoadingIndicator) {
        setLoading(false);
      }
      throw error;
    }
  };

  const switcherRequired = () => {
    if (!user.last_used_account) return false;

    const inList = find(user.account_users, (accountUser) => {
      return user.last_used_account === accountUser.account.id;
    });

    return (
      user.account_users.length > 1 ||
      (user.account_users.length === 1 && !inList)
    );
  };

  const fetchUser = async () => {
    // console.log(`[fetchUser]`)
    let _user: UserMe;

    try {
      // console.log(`[fetchUser] try`)
      _user = await client.Users.getMe();
    } catch (_error) {
      // Incase the user is not confirmed, we get a 401 with UserMe in response.data
      _user = get(_error as any, "response.data") as UserMe;
    }

    // console.log(`[fetchUser] _user`, _user)
    return _user;
  };

  const logout = () => {
    console.log(`[logout]`);
    const token = getToken();
    if (token && token?.refresh_token) {
      setLoading(true);
      const refresh_token = token.refresh_token;
      return client.Users.logout(refresh_token).finally(() => {
        clearSession();
        const params = deleteQueryParam("token");
        history.push(`/${slug}/login${params}`);
        setLoading(false);
      });
    }
    clearSession();
    const params = deleteQueryParam("token");
    history.push(`/${slug}/login${params}`);
    localStorage.setItem("hideTrialBanner", "false");
    return Promise.resolve({});
  };

  const hasPermission = (permissionKey: PermissionKeyType) => {
    const permission = Permissions.find((p) => p.key === permissionKey);
    if (!permission) return true;

    const currentUserAccount = getCurrentUserAccount(user, slug);
    let hasUserRoles = false;

    if (currentUserAccount) {
      const intersected = intersection(
        permission.roles,
        currentUserAccount.roles.map((r: any) => r.slug)
      );
      hasUserRoles = !isEmpty(intersected);
    } else {
      // Remote login
      hasUserRoles = hasPartnerPortalManagerRole(user);
    }
    return hasUserRoles;
  };

  const hasRole = (role: UserRoles) => {
    const currentUserAccount = getCurrentUserAccount(user, slug);
    let hasUserRole = false;
    if (currentUserAccount) {
      hasUserRole = !!currentUserAccount.roles.find(
        (r: any) => r.slug === role
      );
    } else {
      // Remote login
      hasUserRole = hasPartnerPortalManagerRole(user);
    }
    return hasUserRole;
  };

  const hasFeature = (feature: FeedType) => {
    if (!isClient(account.account)) return true;
    const currentFeedTypes =
      account.account.subscriptions[0].plan.channel_types.map((t) => t.slug);
    return currentFeedTypes.includes(feature);
  };

  const hasTrialStarted = () => {
    const accountClone = account.account as any;

    if (accountClone.extra_trial_choice) {
      return true;
    }

    return false;
  };

  const hasTrialEnded = () => {
    const accountClone = account.account as any;

    if (accountClone.is_extra_trial_finished) {
      return true;
    }

    return false;
  };

  const value = {
    partnerSettings,
    user,
    account,
    slug,
    showPoweredBy,
    title,
    setTitle,
    theme,
    setTheme,
    currentRoute,
    exactRoute,
    client,
    setClient,
    loading,
    setLoading,
    error,
    setError,
    logout,
    goTo,
    authenticated,
    activated,
    redirect,
    switchAccount,
    _switchAccount,
    myChannels,
    setMyChannels,
    setConfirmEmailModalIsOpen,
    confirmEmailModalIsOpen,
    fetchUser,
    switcherRequired,
    CtaConfig,
    setCtaConfig,
    resetCtaConfig,
    deleteChannel,
    setDeleteChannel,
    setReAuthenticate,
    notificationConfig,
    setNotificationConfig,
    hasPermission,
    hasRole,
    hasFeature,
    requestFeatureModalIsOpen,
    setRequestFeatureModalIsOpen,
    switchAccountModalIsOpen,
    setSwitchAccountModalIsOpen,
    appDisplayMode,
    seenspireConnectConfig,
    stickyNotification,
    setStickyNotification,
    acceptTermsModalIsOpen,
    setAcceptTermsModalIsOpen,
    hasTrialStarted,
    hasTrialEnded,
    requestTrialModalIsOpen,
    setRequestTrialModalIsOpen,
    myStreams,
    setMyStreams,
    loadedStream,
    setLoadedStream,
    deleteStreamActiveChannel,
    setDeleteStreamActiveChannel,
    openStreams,
    setOpenStreams,
    twitterEnabled,
    reAuthenticate,
    isParentMaster:
      get(account, "account.parent.organization_info.slug") === "seenspire",
  };

  return <Provider value={value}>{props.children}</Provider>;
};

export const useAppContext = () => useContext(AppContext);

export { AppProvider as Provider, Consumer, AppContext };
