import React, {
  FunctionComponent,
  useContext,
  useState,
  useEffect,
} from "react";
import { AppContext } from "../../state";
import {
  styled,
  Block,
  unit,
  Icon,
  Button,
  hsla,
  Media,
  css,
  renderShadow,
  TransitionBase,
} from "../../../seenspire-library";
import { useMedia } from "../../util";
import { useTranslation, Trans } from "react-i18next";
import LinkButton, { AppLink, Link } from "../LinkButton/LinkButton";
import { SidePadding } from "../Layout/Layout";
import { Menu, MenuHeader, MenuSection, MenuButton } from "../Menu/Menu";
import AccountRoutes from "../../routes/AccountRoutes";
import { excludeSlugFromPathname, RoutesNames, canView } from "../../routes";
import { Avatar } from "../Avatar/Avatar";
import { AuthRoutNames } from "../../routes/AuthRoutes";
import { get, isEmpty, chain, last } from "lodash";
import { AccountTypeEnum } from "../../interfaces";
import { HELP_URL } from "../../backend/consts";
import { Account } from "seenspire-api-client";
import { useScrollPosition } from "../../hooks/useScrollPosition";
import bell from "../../../assets/bell_default.svg";
import useFirebaseAnon from "../../containers/AccountLicenceOverview/useFirebaseAnon";
import { useAccountInvoices } from "../../containers/AccountInvoices/useAccountInvoices";
import NotificationBanner from "../NotificationBanner/NotificationBanner";

const HeaderHeight = css`
  height: ${(p) => unit(p.theme.header.height)};
  ${Media.lt.large`
    height: ${(p) => unit(p.theme.header.height - 10)};
  `};
`;

// background: linear-gradient(to bottom, ${({theme}) =>theme.colors.primary.darker} 0%, ${({theme}) =>hsla(theme.colors.primary.darker, 0.5)} 30%, rgba(0,0,0,0) 100%);
const HeaderStyled = styled(Block).attrs({})<{
  scrollY?: number;
  dynamicBackground?: boolean;
  activateHeaderBackground?: boolean;
  stickyNotificationIsActive: boolean;
  showSubscriptionBanner?: boolean;
}>`
  width: 100%;
  display: flex;
  position: fixed;
  top: ${({ theme, stickyNotificationIsActive, showSubscriptionBanner }) =>
    stickyNotificationIsActive ? unit(theme.header.height) : showSubscriptionBanner ? '50px' : 0};
  left: 0;
  align-items: center;
  z-index: ${({ theme }) => theme.header.zIndex};
  background-color: ${({
    theme,
    dynamicBackground,
    activateHeaderBackground,
  }) =>
    dynamicBackground && !activateHeaderBackground
      ? "transparent"
      : theme.colors.primary.dark};
  /* box-shadow: ${({ theme, dynamicBackground, activateHeaderBackground }) =>
    !(dynamicBackground && !activateHeaderBackground) &&
    renderShadow(theme.shadows.darker, theme.colors.primary.dark)}; */
  ${HeaderHeight}
  ${SidePadding}
  ${TransitionBase};
`;

const TITLE_TOP_SPACING = {
  default: 35,
  default_without_menu: 75,
  small: 50,
};
const LogoContainer = styled(Block)`
  z-index: 10;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  ${Media.lt.large`
    width: 150px;
  `}
`;

const LabelBlock = styled(Block)`
  /* max-width: 100px; */
  white-space: nowrap;
  /* overflow: hidden; */
`;

const DropDownButton = styled(Button)`
  box-shadow: none;
  ${TransitionBase};
  border: 1px solid transparent;
  /* min-width: 160px; */
  ${Media.at.small`
    min-width: 50px;
  `}
  ${LabelBlock} {
    ${TransitionBase};
  }
  svg {
    ${TransitionBase};
  }
  &:hover,
  &:focus {
    background-color: ${({ theme }) => hsla(theme.colors.white.base, 0.1)};
    color: ${({ theme }) => theme.colors.gray.lightest};
    ${TransitionBase};
    ${LabelBlock} {
      ${TransitionBase};
      color: ${({ theme }) => theme.colors.gray.lightest};
    }
    svg {
      fill: ${({ theme }) => theme.colors.gray.lightest};
      ${TransitionBase};
    }
  }
`;

// ${HeaderHeight};
const Title = styled(Block)<{ collapse: boolean; withMenu: boolean }>`
  position: absolute;
  left: 50%;
  font-size: 30px;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.white.base};
  display: flex;
  align-items: center;
  justify-content: center;
  /* ${TransitionBase}; */
  transition: all 150ms ease-in-out;
  transform: ${({ collapse, withMenu }: any) =>
    collapse
      ? `translateY(0px) translateX(-50%) scale(0.7)`
      : `translateY(${
          withMenu
            ? TITLE_TOP_SPACING.default
            : TITLE_TOP_SPACING.default_without_menu
        }px) translateX(-50%) scale(1)`};
  opacity: ${({ collapse }) => (collapse ? 0.5 : 1)};
  ${Media.lt.large`
    font-size: 20px;
    transform: ${({ collapse }: any) =>
      collapse
        ? `translateY(0px) translateX(-50%) scale(0.7)`
        : `translateY(${TITLE_TOP_SPACING.small}px) translateX(-50%) scale(1)`};
    opacity: 1;
  `}
`;

const SubscriptionText = styled(Block)`
  text-transform: uppercase;
  color: #feb454;
  width: 100%;
  text-align: right;
`;

const TrialLink = styled(Block)`
  a {
    color: ${({ theme }) => theme.colors.white.base};
  }
`;

export const SpacedSpan = styled.span`
  padding-right: 5px;
  padding-left: 5px;
  font-weight: 600;
`;

const StartSubscriptionBlock = styled(Block).attrs({})<{ noBorder?: boolean }>`
  border-right: solid 1px
    ${({ theme, noBorder }) =>
      noBorder ? "transparent" : hsla(theme.colors.gray.base, 0.2)};
  padding-right: 30px;
  height: 40px;
`;

const BellContainer = styled(Block)`
  position: relative;
  margin-top: 8px;
  margin-right: 8px;
  padding-left: 4px;
  padding-bottom: 4px;
`;

const Notification = styled(Block)`
  position: absolute;
  width: 22px;
  height: 22px;
  top: -4px;
  right: -4px;
`;

const Header: FunctionComponent = () => {
  const Context = useContext(AppContext);
  const {
    title,
    user,
    account,
    currentRoute,
    logout,
    partnerSettings,
    authenticated,
    activated,
    _switchAccount,
    switcherRequired,
    hasRole,
    stickyNotification: { active: stickyNotificationIsActive },
    setConfirmEmailModalIsOpen,
    hasTrialStarted,
    hasTrialEnded,
  } = Context;
  const { first_name, last_name, account_users } = user;
  const M = useMedia();
  const { t } = useTranslation();
  const { data } = useFirebaseAnon(account.account);
  const [isTitleCollapse, setIsTitleCollaspe] = useState(
    window.scrollY >= TITLE_TOP_SPACING.default
  );
  const [menuButtonHovered, setMenuButtonHovered] = useState(false);
  const [headerMenuOpen, setHeaderMenuOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [showStartSubscriptionBlock, setStartSubscriptionBlock] =
    useState(false);
  const [showPendingUpgradeRequest, setShowPendingUpgradeRequest] =
    useState(false);

  const { invoices } = useAccountInvoices();

  useScrollPosition(
    ({ currPos }: { currPos: { y: number } }) => {
      setIsTitleCollaspe(currPos.y >= TITLE_TOP_SPACING.default);
    },
    [],
    null,
    true,
    100
  );

  useEffect(() => {
    console.log(">>>>>> ISSUE 1");
    console.log(account);
    const currentUpgradeRequests = chain(account.account.subscriptions)
      .first()
      .get("upgrade_requests")
      // @ts-ignore
      .value() as any[];
    const _showPendingUpgradeRequest =
      account.account.status === Account.StatusEnum.TRIAL &&
      currentRoute.name === "dashboard" &&
      last(currentUpgradeRequests) &&
      last(currentUpgradeRequests).status === "PENDING";
    setShowPendingUpgradeRequest(_showPendingUpgradeRequest);
    if (!_showPendingUpgradeRequest) {
      const _showStartSubscriptionBlock =
        account.account.status === Account.StatusEnum.TRIAL &&
        currentRoute.name === "dashboard" &&
        account &&
        account.account.status === "TRIAL" &&
        activated;
      setStartSubscriptionBlock(_showStartSubscriptionBlock);
    }
  }, [currentRoute, account, activated]);

  useEffect(() => {
    const trialBanner = localStorage.getItem("hideTrialBanner");
  
    if (trialBanner === "true") {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [logout]);

  const userName = `${first_name} ${last_name}`;
  const accountSettingsDropdownItems = AccountRoutes.filter((route) => {
    // if (route.name === "account_licenceOverview") {
      // return data && data.length > 0;
    // }
    if (route.name === "account_invoices") {
      return invoices && invoices.length > 0;
    }
    return true;
  })
    .filter((route) => canView(route, Context, true))
    .filter(
      (route) =>
        route.name === "account_home" ||
        route.name === "account_networkConnections" ||
        route.name === "account_feedSettings" ||
        route.name === "account_partnerSettings" ||
        route.name === "account_users" ||
        route.name === "account_invoices" || 
        route.name === "account_licenceOverview"
    );

  // Hide menu on these route names
  if (
    (
      [
        "slider",
        "slider_preview",
        "redirection",
        ...AuthRoutNames,
      ] as RoutesNames[]
    ).includes(currentRoute.name)
  )
    return null;

  // Change header background based on the route name, Dashboard (for example) requires dynamic changes in bg color
  const requiresDynamicBackground = (["dashboard"] as RoutesNames[]).includes(
    currentRoute.name
  );

  const profileLinkContent = (
    <LabelBlock
      fontWeight="thin"
      fontSize="small"
      color="cta"
      padding={{ top: 6 }}
    >
      Profile settings
    </LabelBlock>
  );

  let today: Date = new Date();
  let startedAt: Date;
  let differenceInTime;
  let differenceInDays = 0;
  if (account.account.extra_trial_started_at) {
    startedAt = new Date(account.account.extra_trial_started_at);
    startedAt.setDate(startedAt.getDate() + 15);
    differenceInTime = startedAt.getTime() - today.getTime();
    differenceInDays = differenceInTime / (1000 * 3600 * 24);
  }

  if ((window as any).pendo) {
    (window as any).pendo.initialize({
      account: {
        id: account.id,
        full_name: `${user.first_name} ${user.last_name}`,
      },
    });
  }

  const handleClose = () => {
    setIsVisible(true);
    localStorage.setItem("hideTrialBanner", "true");
  };

  const excludeVxt = (userAccount: any) => !userAccount.account.organization_info.name.includes('vxt-');

  const canManageSubscription = hasRole("ROLE_OWNER") || hasRole("ROLE_PARTNER_PORTAL_MANAGER");
  const isLeadAccount = account?.account?.is_lead;
  const trialExpiresAt = account?.account?.subscriptions?.[0]?.expires_at;
  const hasSubscriptionPermissions = canManageSubscription && !isLeadAccount;

  return (
    <>
      {(showStartSubscriptionBlock || showPendingUpgradeRequest) && (
        <NotificationBanner
          trialExpiresAt={trialExpiresAt}
          showPendingUpgradeRequest={showPendingUpgradeRequest}
          hasSubscriptionPermissions={hasSubscriptionPermissions}
          isVisible={isVisible}
          handleClose={handleClose}
        />
      )}
      <HeaderStyled
      dynamicBackground={requiresDynamicBackground}
      activateHeaderBackground={true}
      stickyNotificationIsActive={stickyNotificationIsActive}
      showSubscriptionBanner={!isVisible && (showStartSubscriptionBlock || showPendingUpgradeRequest)}
    >
      <Title
        collapse={isTitleCollapse}
        withMenu={
          !!(
            currentRoute.routes &&
            currentRoute.routes.length > 0 &&
            !currentRoute.skipSubMenu
          )
        }
      >
        {title}
      </Title>
      <AppLink to={`dashboard`}>
        <LogoContainer>
          <img
            alt="logo"
            style={{ maxHeight: 35, maxWidth: 125 }}
            src={partnerSettings.navigation_logo}
          ></img>
        </LogoContainer>
      </AppLink>

      <Block flex={1} />

      {M.isSmall || M.isMedium ? (
        <DropDownButton elementWidth="free" bg="transparent">
          <Icon name="menu" />
        </DropDownButton>
      ) : (
        <>
          <Block
            display="grid"
            gridGap={20}
            direction="column"
            alignItems="center"
            margin={{ right: 10 }}
          >
            {hasTrialStarted() && !hasTrialEnded() && (
              <StartSubscriptionBlock
                display="grid"
                direction="column"
                gridGap={10}
                justifyItems="center"
                alignItems="center"
                noBorder={account.account.is_lead}
              >
                <Block
                  display="grid"
                  gridGap={5}
                  alignItems="center"
                  alignContent="center"
                  textAlign="right"
                  color="white"
                  opacity={0.8}
                  fontWeight="bold"
                >
                  <AppLink to={"account/subscription"}>
                    <SubscriptionText>
                      {showPendingUpgradeRequest
                        ? "Upgrade request pending"
                        : "UPGRADE CURRENT SUBSCRIPTION"}
                    </SubscriptionText>
                  </AppLink>
                  <TrialLink>
                    <span>Trial expires in </span>{" "}
                    {Math.floor(differenceInDays)} <span>days</span>
                  </TrialLink>
                </Block>
              </StartSubscriptionBlock>
            )}

            {activated &&
              [
                AccountTypeEnum.master,
                AccountTypeEnum.distributor,
                AccountTypeEnum.reseller,
              ].includes(account.account.type as any) &&
              hasRole("ROLE_PARTNER_PORTAL_MANAGER") && (
                <LinkButton to={`partner/home`}>
                  <Block fontSize="smaller">{t("partner portal")}</Block>
                </LinkButton>
              )}

            <LinkButton url={true} target="_blank" to={HELP_URL}>
              <Block fontSize="smaller">{t("auth.email_is_centre")}</Block>
            </LinkButton>

            <BellContainer fontSize="smaller">
              <img src={bell} />
              <Notification className="beamerTrigger" />
            </BellContainer>
          </Block>

          <Menu
            overlay
            bottomSpacing={10}
            onMenuOpen={setHeaderMenuOpen}
            menuButton={
              <Block
                display="grid"
                direction="column"
                justifyItems="center"
                alignItems="center"
                gridGap={5}
                onMouseOver={() => setMenuButtonHovered(true)}
                onMouseLeave={() => setMenuButtonHovered(false)}
              >
                {authenticated ? (
                  <>
                    <Avatar
                      margin={{ left: 15 }}
                      user={user}
                      avatarSize="medium"
                      hovered={menuButtonHovered || headerMenuOpen}
                    />
                    <Block
                      opacity={menuButtonHovered || headerMenuOpen ? 1 : 0.5}
                      cursor="pointer"
                      justifySelf="start"
                      padding={{ left: 5, right: 5 }}
                      transition
                    >
                      <LabelBlock
                        display="flex"
                        justifyContent="start"
                        fontSize="small"
                        color="white"
                      >
                        {userName}
                      </LabelBlock>
                      <LabelBlock
                        display="flex"
                        fontWeight="thin"
                        fontSize="smaller"
                        color="gray"
                      >
                        {get(partnerSettings, "name") ||
                          account.account.organization_info.name}
                      </LabelBlock>
                    </Block>
                  </>
                ) : (
                  <Icon name="loading" strokeColor="white" />
                )}
              </Block>
            }
          >
            <Block color="gray" shade="dark">
              <MenuHeader>
                <Block padding={{ all: 10 }}>
                  <Avatar user={user} avatarSize="large" />
                </Block>

                <Block
                  display="grid"
                  gridGap={3}
                  padding={{ all: 10, left: 6 }}
                >
                  <LabelBlock
                    fontWeight="bold"
                    color="black"
                    style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                  >
                    {userName}
                  </LabelBlock>
                  <LabelBlock
                    fontWeight="thin"
                    fontSize="small"
                    color="gray"
                    shade="light"
                    textTransform="lowercase"
                    style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                  >
                    {user.email}
                  </LabelBlock>
                  {activated ? (
                    <AppLink to="profile">{profileLinkContent}</AppLink>
                  ) : (
                    <Block
                      cursor="pointer"
                      onClick={() => setConfirmEmailModalIsOpen(true)}
                    >
                      {profileLinkContent}
                    </Block>
                  )}
                </Block>
              </MenuHeader>

              {switcherRequired() && (
                <MenuSection bg="background" borderTop={true} limitedHeight>
                  {account_users.filter(excludeVxt).map(({ account: userAccount }: any, key) => (
                    <MenuButton
                      margin={{ top: 5 }}
                      key={key}
                      onClick={() =>
                        _switchAccount(userAccount, "", true, true)
                      }
                      withIcon
                      buttonType="transparent"
                    >
                      {/* Check mark */}
                      {account_users.filter(excludeVxt).length > 1 && (
                        <Icon
                          iconSize="large"
                          name={"checkBlue"}
                          opacity={
                            userAccount.organization_info.slug ===
                            account.account.organization_info.slug
                              ? 1
                              : 0
                          }
                        />
                      )}
                      {/* Account Name */}
                      <Block
                        textAlign="left"
                        display="grid"
                        margin={{ left: 6 }}
                      >
                        <Block fontWeight="bold">
                          {userAccount.organization_info.name}
                        </Block>
                        <Block fontSize="small" opacity={0.5}>
                          {userAccount.type}
                        </Block>
                      </Block>
                    </MenuButton>
                  ))}
                </MenuSection>
              )}

              {!isEmpty(accountSettingsDropdownItems) && (
                <MenuSection
                  borderTop={true}
                  onClick={() => !activated && setConfirmEmailModalIsOpen(true)}
                >
                  {accountSettingsDropdownItems.map((route, index) => (
                    <AppLink
                      key={index}
                      to={
                        activated
                          ? excludeSlugFromPathname(route.path)
                          : "dashboard"
                      }
                    >
                      <MenuButton buttonType="transparent">
                        {t(route.label)}
                      </MenuButton>
                    </AppLink>
                  ))}
                </MenuSection>
              )}
              <MenuSection borderTop={true}>
                {[
                  AccountTypeEnum.master,
                  AccountTypeEnum.distributor,
                  AccountTypeEnum.reseller,
                ].includes(account.account.type as any) &&
                  hasRole("ROLE_PARTNER_PORTAL_MANAGER") && (
                    <AppLink to={`partner/home`}>
                      <MenuButton buttonType="transparent">
                        {t("Partner Portal")}
                      </MenuButton>
                    </AppLink>
                  )}

                {[
                  AccountTypeEnum.master,
                  AccountTypeEnum.contentPartner,
                ].includes(account.account.type as any) &&
                  hasRole("ROLE_OWNER") && (
                    <AppLink to={`content-partner/dashboard`}>
                      <MenuButton buttonType="transparent">
                        {t("Content Partner")}
                      </MenuButton>
                    </AppLink>
                  )}

                <Link url={true} target="_blank" to={HELP_URL}>
                  <MenuButton buttonType="transparent">
                    {t("auth.email_is_centre")}
                  </MenuButton>
                </Link>
              </MenuSection>

              <MenuSection display="flex" borderTop={true}>
                <Button
                  onClick={() => logout()}
                  buttonType="outline"
                  elementHeightBase={false}
                  padding={{ top: 10, bottom: 10 }}
                  elementWidth="block"
                >
                  {t("Logout")}
                </Button>
              </MenuSection>
            </Block>
          </Menu>
        </>
      )}
    </HeaderStyled>
    </>
  );
};

export { Header, HeaderStyled };
