import React, { useMemo, useState } from 'react';
import { format, differenceInDays, isPast } from 'date-fns';

import { styled, Icon } from '../../../seenspire-library';
import { AppLink } from '../LinkButton/LinkButton';

const NotificationWrapper = styled.div`
  width: 100%;
  height: 50px;
  background-color: #0643f8;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
`;

const NotificationContent = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const TrialInfo = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;
  padding: 4px 12px;
  border-radius: 8px;
  font-weight: 500;
`;

const IconWrapper = styled.div`
  position: relative;
  width: fit-content;
  display: flex;
  align-items: center;
`;

const IconBadge = styled.div`
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
  font-size: 10px;
  font-weight: 700;
  line-height: 1;
`;

const DaysBadge = styled.div`
  display: block;
`;

const DayInfo = styled.div`
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  text-transform: uppercase;
`;

const TrailEnd = styled.div`
  color: #fff;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
`;

const StartButton = styled(AppLink)`
  background-color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s ease;
  text-transform: uppercase;
  color: #121f3f;
  font-size: 10px;
  font-style: normal;
  line-height: 14px;
  text-decoration: none;

  &:hover {
    background-color: #f0f0f0;
  }

  &[disabled] {
    background-color: #e0e0e0;
    color: #a0a0a0;
    cursor: not-allowed;
  }
`;

const CloseButton = styled(Icon)`
  position: absolute;
  right: 20px;
  cursor: pointer;
`;

interface NotificationBannerProps {
  trialExpiresAt: string | Date | null | undefined;
  showPendingUpgradeRequest: boolean;
  hasSubscriptionPermissions: boolean;
  isVisible: boolean;
  handleClose: () => void;
}

const NotificationBanner: React.FC<NotificationBannerProps> = ({
  trialExpiresAt,
  showPendingUpgradeRequest,
  hasSubscriptionPermissions,
  isVisible,
  handleClose
}) => {
  const trialData = useMemo(() => {
    if (!trialExpiresAt) {
      return { remainingDays: 0, formattedEndDate: '', isValid: false };
    }

    const now = new Date();
    const endDate = new Date(trialExpiresAt);

    if (isPast(endDate)) {
      return { remainingDays: 0, formattedEndDate: format(endDate, 'MMMM do'), isValid: false };
    }

    const remainingDays = differenceInDays(endDate, now) + 1;
    const clampedDays = Math.max(0, Math.min(14, remainingDays));

    const formattedEndDate = format(endDate, 'MMMM do');

    return {
        remainingDays: clampedDays,
        formattedEndDate,
        isValid: true,
    };
  }, [trialExpiresAt]);

  if (!trialData.isValid || isVisible) {
    return null;
  }

  const buttonText = showPendingUpgradeRequest ? "Upgrade request pending" : "Start Subscription";

  const daysText = trialData.remainingDays === 1 ? 'Day' : 'Days';

  return (
    <NotificationWrapper>
      <NotificationContent>
        <TrialInfo>
          <IconWrapper>
            <Icon name="group" iconSize="medium" />
            <IconBadge>{trialData.remainingDays}</IconBadge>
          </IconWrapper>
          <DaysBadge>
            <DayInfo>{trialData.remainingDays} {daysText} Remaining</DayInfo>
            <TrailEnd>Your trial ends on {trialData.formattedEndDate}</TrailEnd>
          </DaysBadge>
        </TrialInfo>
        {hasSubscriptionPermissions && (
          <StartButton
            to="account/subscription"
          >
            {buttonText}
          </StartButton>
        )}
      </NotificationContent>
      <CloseButton name="close" iconSize='small' color='white' onClick={handleClose} />
    </NotificationWrapper>
  );
};

export default NotificationBanner;
